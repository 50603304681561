import { render, staticRenderFns } from "./MinimizeTransition.vue?vue&type=template&id=5af33a19&"
import script from "./MinimizeTransition.vue?vue&type=script&lang=js&"
export * from "./MinimizeTransition.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports